@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrap {
	width: 100%;
	max-width: 414px;
	margin: 0 auto;
	padding: 15px (150px * $m320) 25px;
	text-align: center;

	@media (min-height: 600px) {
		padding: 15px (150px * $m320) 50px;
	}
}

.title {
	font-family: $font-family-trim;
	font-weight: $trim-bold;
	font-size: round(120px * $m320);
	line-height: round(110px * $m320);
	text-transform: uppercase;

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(140px * $m360);
		line-height: round(120px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(160px * $m375);
		line-height: round(140px * $m375);
	}

	.wrap_de & {
		font-size: round(95px * $m320);
		line-height: round(88px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(105px * $m320);
			line-height: round(96px * $m320);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(120px * $m320);
			line-height: round(110px * $m320);
		}
	}
}

.subtitle {
	padding: 10px 0 0;
	font-size: round(65px * $m320);
	line-height: round(65px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(70px * $m360);
		line-height: round(70px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(72px * $m375);
		line-height: round(72px * $m375);
	}

	.wrap_de & {
		font-size: round(58px * $m320);
		line-height: round(58px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(62px * $m360);
			line-height: round(62px * $m360);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(65px * $m375);
			line-height: round(65px * $m375);
		}
	}
}

.body {
	padding: 16px 0 0;
	font-size: round(40px * $m360);
	line-height: round(56px * $m360);

	@media (min-height: 600px) and (min-width: 360px) {
		padding: 22px 0 0;
	}
	@media (min-height: 650px) and (min-width: 375px) {
		padding: 26px 0 0;
		font-size: round(40px * $m375);
		line-height: round(56px * $m375);
	}

	.wrap_de & {
		padding: 14px 0 0;

		@media (min-height: 600px) and (min-width: 360px) {
			padding: 18px 0 0;
		}
		@media (min-height: 650px) and (min-width: 375px) {
			padding: 22px 0 0;
		}
	}
}

.buttonGroup {
	margin-top: 20px;
}

.btn {
	max-width: 200px;
	padding: 5px 15px;
	border-radius: 50px !important;
	font-size: calc(var(--scale-factor) * 12px) !important;
	line-height: 1.4 !important;

	.wrap_de & {
		@media (min-height: 600px) and (min-width: 360px) {
			padding: 7px 15px;
		}
	}
}

.btn1 {
	margin: 0 auto !important;

	.wrap_de & {
		margin: 16px auto 0;
	}
}
.btn2 {
	margin: 12px auto 0 !important;

	@media (min-height: 600px) {
		margin: 16px auto 0 !important;
	}

	.wrap_de & {
		margin: 8px auto 0;
	}
}
