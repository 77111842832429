@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.header {
	grid-area: header;
	display: flex;
	position: relative;
	justify-content: center;

	padding-bottom: calc(10px + 4vh);
	padding-bottom: 10px;

	pointer-events: none;
	// z-index: 2;

	@media (min-height: 600px) {
		padding-bottom: 20px;
	}
}

.logo {
	display: none;

	margin-top: 40px * $m320;
	width: 200px * $m320;

	svg {
		display: block;
	}

	@media (min-height: 500px) {
		display: block;
	}

	@media (min-height: 600px) {
		margin-top: 60px * $m320;
		width: 240px * $m320;

		[data-locale="za"] & {
		width: 270px * $m320;
		margin-top: 90px * $m320;
		}
	}

	// @media (min-height: 600px) and (min-width: 360px) {
	// 	margin-top: 70px * $m360;
	// 	width: 230px * $m360;
	// }
	@media (min-height: 680px) and (min-width: 375px) {
		margin-top: 90px * $m375;
		width: 254px * $m375;

		[data-locale="za"] & {
			width: 280px * $m320;
			margin-top: 120px * $m375;
			}
	}
}
