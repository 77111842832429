@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.footer {
	// position: absolute;
	position: relative;
	grid-area: footer;

	padding: 0 18px 10px;

	text-align: center;
	font-weight: $gotham-bold;
	font-size: 24px * $m375;
	line-height: 24px * $m375;
	text-transform: uppercase;
	color: #fff;
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

	pointer-events: none;

	// z-index: 2;

	& > * {
		pointer-events: all;
	}
}

.terms {
	float: left;
}

.privacy {
	float: right;
}
