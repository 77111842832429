@import '../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../node_modules/bat-components/dist/styles/includes/functions';

:global(html),
:global(body) {
	min-height: var(--vh100) !important;
	height: var(--vh100) !important;
}

main.main {
	display: block;
	height: 100%;
	white-space: pre-wrap;

	font-weight: $gotham-light;

	// grid-template-columns: 1fr;
	// grid-template-rows: auto 1fr auto;
	// grid-template-areas:
	// 	'header'
	// 	'content'
	// 	'footer';
}

.outer {
	height: 100%;
	overflow: auto;
}

.inner {
	display: grid;
	min-height: 100%;
	overflow: auto;

	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		'header'
		'content'
		'footer';
}
