@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.layout {
	position: relative;
	overflow: hidden;

	display: flex;
	align-items: center;
	grid-area: content;

	width: 100%;

	z-index: 5;

	color: white;
}
