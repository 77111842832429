@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.blocker {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background: rgba(255, 0, 0, 0.2);
}
