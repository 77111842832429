@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrap {
	max-width: 414px;
	margin: 0 auto;
	padding: 15px round(150px * $m320) 180px;
	text-align: center;
	opacity: 0;
	transition: 500ms ease-in-out;

	@media (min-height: 500px) {
		padding: 15px round(150px * $m320) 200px;
	}
	@media (min-height: 540px) {
		padding: 15px round(150px * $m320) 240px;
	}
	@media (min-height: 580px) {
		padding: 15px round(150px * $m320) 250px;
	}
	@media (min-height: 600px) {
		padding: 15px round(150px * $m320) 260px;
	}

	@media (min-width: 360px) {
		@media (min-height: 680px) {
			padding: 15px round(150px * $m320) 290px;
		}
	}
	@media (min-width: 375px) {
		@media (min-height: 720px) {
			padding: 15px round(150px * $m320) 310px;
		}
	}
	@media (min-width: 390px) {
		@media (min-height: 750px) {
			padding: 15px round(150px * $m320) 330px;
		}
	}
	@media (min-width: 425px) {
		@media (min-height: 800px) {
			padding: 15px round(150px * $m320) 350px;
		}
	}
	@media (min-width: 440px) {
		@media (min-height: 850px) {
			padding: 15px round(150px * $m320) 370px;
		}
	}

	// @media (min-height: 440px) {
	// 	padding: round(300px * $m320) round(150px * $m320) 0;
	// }
	// @media (min-height: 600px) and (min-width: 360px) {
	// 	padding: round(330px * $m360) round(150px * $m360) 0;
	// }
	// @media (min-height: 650px) and (min-width: 375px) {
	// 	padding: round(340px * $m375) round(150px * $m375) 0;
	// }
	// @media (min-height: 680px) and (min-width: 375px) {
	// 	padding: round(420px * $m375) round(150px * $m375) 0;
	// }

	&.wrap_de {
		padding: 0 round(150px * $m320) 0;

		// @media (min-height: 440px) {
		// 	padding: round(280px * $m320) round(150px * $m320) 0;
		// }
		// @media (min-height: 600px) and (min-width: 360px) {
		// 	padding: round(300px * $m360) round(150px * $m360) 0;
		// }
		// @media (min-height: 650px) and (min-width: 375px) {
		// 	padding: round(310px * $m375) round(150px * $m375) 0;
		// }
		// @media (min-height: 680px) and (min-width: 375px) {
		// 	padding: round(400px * $m375) round(150px * $m375) 0;
		// }
	}
}
.wrap_visible {
	opacity: 1;
}

.title {
	font-family: $font-family-trim;
	font-weight: $trim-bold;
	font-size: round(90px * $m320);
	line-height: round(80px * $m320);
	text-transform: uppercase;

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(90px * $m360);
		line-height: round(80px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(90px * $m375);
		line-height: round(80px * $m375);
	}

	.wrap_de & {
		font-size: round(75px * $m320);
		line-height: round(70px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(75px * $m360);
			line-height: round(70px * $m360);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(75px * $m375);
			line-height: round(70px * $m375);
		}
		@media (min-height: 440px) and (min-width: 414px) {
			font-size: round(85px * $m375);
			line-height: round(75px * $m375);
		}
	}
}

.body {
	padding: round(35px * $m320) 0 0;
	font-weight: $gotham-book;
	font-size: round(52px * $m320);
	line-height: round(60px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		padding: round(35px * $m360) 0 0;
		font-size: round(52px * $m360);
		line-height: round(60px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		padding: round(35px * $m375) 0 0;
		font-size: round(52px * $m375);
		line-height: round(60px * $m375);
	}

	.wrap_de & {
		font-size: round(45px * $m320);
		line-height: round(55px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(45px * $m360);
			line-height: round(55px * $m360);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(45px * $m375);
			line-height: round(55px * $m375);
		}
	}
}

.flavour {
	padding: round(130px * $m320) 0 0;
	font-weight: $gotham-medium;
	font-size: round(36px * $m320);
	line-height: round(44px * $m320);
	text-transform: uppercase;
	opacity: 0;

	position: absolute;
	bottom: 110px;
	left: 0;
	width: 100%;
	padding: 0;
	transition: 300ms ease-in-out;

	@media (min-height: 440px) {
		bottom: 115px;
	}
	@media (min-height: 540px) {
		bottom: 140px;
	}
	@media (min-height: 580px) {
		bottom: 155px;
	}
	@media (min-height: 600px) {
		bottom: 170px;
	}

	@media (min-width: 360px) {
		@media (min-height: 680px) {
			bottom: 200px;
		}
	}
	@media (min-width: 375px) {
		@media (min-height: 720px) {
			bottom: 220px;
		}
	}
	@media (min-width: 390px) {
		@media (min-height: 750px) {
			bottom: 230px;
		}
	}

	// @media (min-height: 600px) and (min-width: 360px) {
	// 	bottom: 160px;
	// }
	// @media (min-height: 650px) and (min-width: 375px) {
	// 	bottom: 170px;
	// }
	// @media (min-height: 680px) and (min-width: 375px) {
	// 	bottom: 210px;
	// }
	// @media (min-height: 440px) and (min-width: 414px) {
	// 	bottom: 205px;
	// }
	// @media (min-height: 700px) and (min-width: 414px) {
	// 	bottom: 225px;
	// }
}
.flavour_visible {
	opacity: 1;
}
