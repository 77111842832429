@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrap {
	max-width: 414px;
	margin: 0 auto;
	padding: 15px round(150px * $m320) 90px;
	text-align: center;
	opacity: 0;
	transition: 400ms ease-in-out;

	@media (min-height: 500px) {
		padding: 15px round(150px * $m320) 80px;
	}
	@media (min-height: 540px) {
		padding: 15px round(150px * $m320) 100px;
	}
	@media (min-height: 580px) {
		padding: 15px round(150px * $m320) 140px;
	}

	&.wrap_de {
		padding: 30px round(150px * $m320) 0;
	}
}

.wrap_visible {
	opacity: 1;
}

.found,
.found2 {
	font-size: round(44px * $m320);
	line-height: round(44px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(44px * $m360);
		line-height: round(44px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(44px * $m375);
		line-height: round(44px * $m375);
	}
}

.flavour {
	padding: round(20px * $m320) 0 0;
	font-weight: $gotham-medium;
	font-size: round(50px * $m320);
	line-height: round(50px * $m320);
	text-transform: uppercase;
	white-space: normal;

	@media (min-height: 600px) and (min-width: 360px) {
		padding: round(20px * $m360) 0 0;
		font-size: round(50px * $m360);
		line-height: round(50px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		padding: round(20px * $m375) 0 0;
		font-size: round(50px * $m375);
		line-height: round(50px * $m375);
	}

	.wrap_de & {
		padding: round(16px * $m320) 0;

		@media (min-height: 600px) and (min-width: 360px) {
			padding: round(16px * $m360) 0;
		}
		@media (min-height: 650px) and (min-width: 375px) {
			padding: round(16px * $m375) 0;
		}
	}
}

.title {
	padding: round(40px * $m320) 0 0;
	font-family: $font-family-trim;
	font-weight: $trim-bold;
	font-size: round(110px * $m320);
	line-height: round(100px * $m320);
	text-transform: uppercase;

	@media (min-height: 600px) and (min-width: 360px) {
		padding: round(40px * $m360) 0 0;
		font-size: round(110px * $m360);
		line-height: round(100px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		padding: round(40px * $m375) 0 0;
		font-size: round(110px * $m375);
		line-height: round(100px * $m375);
	}

	.wrap_de & {
		font-size: round(80px * $m320);
		line-height: round(88px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(80px * $m360);
			line-height: round(88px * $m360);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(80px * $m375);
			line-height: round(88px * $m375);
		}
		@media (min-height: 440px) and (min-width: 414px) {
			font-size: round(110px * $m375);
			line-height: round(100px * $m375);
		}
	}
}

.prize {
	min-height: round(222px * $m320);
	padding: 12px 0 0;
	font-weight: $gotham-book;
	font-size: round(70px * $m320);
	line-height: round(74px * $m320);
	text-transform: uppercase;

	@media (min-height: 600px) and (min-width: 360px) {
		min-height: round(222px * $m360);
		font-size: round(70px * $m360);
		line-height: round(74px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		min-height: round(222px * $m375);
		font-size: round(70px * $m375);
		line-height: round(74px * $m375);
	}

	.wrap_de & {
		font-size: round(60px * $m320);
		line-height: round(64px * $m320);

		@media (min-height: 600px) and (min-width: 360px) {
			font-size: round(60px * $m360);
			line-height: round(64px * $m360);
		}
		@media (min-height: 650px) and (min-width: 375px) {
			font-size: round(60px * $m375);
			line-height: round(64px * $m375);
		}
	}
}

.body {
	padding: round(50px * $m320) 0 0;
	font-size: round(56px * $m320);
	line-height: round(64px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		padding: round(50px * $m360) 0 0;
		font-size: round(56px * $m360);
		line-height: round(64px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		padding: round(50px * $m375) 0 0;
		font-size: round(56px * $m375);
		line-height: round(64px * $m375);
	}
}

.btn {
	max-width: 200px;
	padding: 5px 15px;
	border-radius: 50px !important;
	font-size: calc(var(--scale-factor) * 12px) !important;
	line-height: 1.4 !important;
}

.btnwin {
	margin: 20px auto 0;
}

.btnlose {
	margin: round(50px * $m320) 0 0;

	@media (min-height: 600px) and (min-width: 360px) {
		margin: round(50px * $m360) 0 0;
	}
	@media (min-height: 650px) and (min-width: 375px) {
		margin: round(50px * $m375) 0 0;
	}
}
