@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrap {
	position: relative;
	max-width: 300px;
	margin: 0 auto;
	text-align: center;

	padding: 0 round(150px * $m320) 40px;

	@media (min-height: 600px) and (min-width: 360px) {
		max-width: 350px;
	}
	@media (min-height: 650px) and (min-width: 375px) {
		max-width: 414px;
	}

	// @media (min-height: 440px) {
	// 	padding: round(370px * $m320) round(150px * $m320) 0;
	// }
	// @media (min-height: 600px) and (min-width: 360px) {
	// 	padding: round(380px * $m360) round(150px * $m360) 0;
	// }
	// @media (min-height: 650px) and (min-width: 375px) {
	// 	padding: round(400px * $m375) round(150px * $m375) 0;
	// }
	// @media (min-height: 680px) and (min-width: 375px) {
	// 	padding: round(420px * $m375) round(150px * $m375) 0;
	// }
}

.title {
	font-family: $font-family-trim;
	font-size: round(100px * $m320);
	line-height: round(90px * $m320);
	font-weight: $trim-bold;
	text-transform: uppercase;
	text-align: center;

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(100px * $m360);
		line-height: round(90px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(100px * $m375);
		line-height: round(90px * $m375);
	}
}

.body {
	margin: 1em auto 0;
	font-size: round(72px * $m320);
	line-height: round(80px * $m320);
	text-align: center;

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(66px * $m360);
		line-height: round(80px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(72px * $m375);
		line-height: round(80px * $m375);
	}

	&:first-child {
		margin-top: 0;
	}
}

.footnote {
	margin: 1em auto 0;
	font-size: round(54px * $m320);
	line-height: round(62px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(54px * $m360);
		line-height: round(62px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(54px * $m375);
		line-height: round(62px * $m375);
	}

	&:first-child {
		margin-top: 0;
	}
}
