@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

.wrap {
	max-width: 414px;
	margin: 0 auto;
	padding: 15px (150px * $m320) 25px;
	text-align: center;

	@media (min-height: 600px) {
		padding: 15px (150px * $m320) 50px;
	}
	@media (min-height: 680px) {
		padding: 15px (150px * $m320) 70px;
	}
}

.title,
.code_title,
.code {
	font-size: round(70px * $m320);
	line-height: round(90px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(70px * $m360);
		line-height: round(90px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(70px * $m375);
		line-height: round(90px * $m375);
	}
}
.code {
	font-weight: $gotham-medium;
	color: #adea4c;
}

.prize_title {
	margin: 1.3em auto 0;
	font-size: round(50px * $m320);
	line-height: round(50px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(50px * $m360);
		line-height: round(50px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(50px * $m375);
		line-height: round(50px * $m375);
	}
}

.prize {
	margin: 0.3em auto 0;
	font-size: round(70px * $m320);
	line-height: round(74px * $m320);
	font-weight: $gotham-medium;
	text-transform: uppercase;

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(70px * $m360);
		line-height: round(74px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(70px * $m375);
		line-height: round(74px * $m375);
	}
}

.body {
	margin: 1.5em auto 0;
	font-size: round(50px * $m320);
	line-height: round(62px * $m320);

	@media (min-height: 600px) and (min-width: 360px) {
		font-size: round(50px * $m360);
		line-height: round(62px * $m360);
	}
	@media (min-height: 650px) and (min-width: 375px) {
		font-size: round(50px * $m375);
		line-height: round(62px * $m375);
	}
}
