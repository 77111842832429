@import '../../../../node_modules/bat-components/dist/styles/includes/vars';
@import '../../../../node_modules/bat-components/dist/styles/includes/placeholders';
@import '../../../../node_modules/bat-components/dist/styles/includes/functions';

// ======================================================================
// GAME
// ======================================================================

.wrapper {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// z-index: 0;
}

.center {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	margin: -5px;
	background: #f00;
}

.game {
	display: block;
	position: absolute;
	overflow: hidden;
	top: 120%;
	left: 50%;
	width: 1536px;
	height: 1536px;
	padding: 0;
	margin: 0 auto;
	transform: translateX(-50%) translateY(-49%);

	@media (min-height: 440px) {
		top: 117%;
	}
	@media (min-height: 470px) {
		top: 115%;
	}
	@media (min-height: 500px) {
		top: 113%;
	}
	@media (min-height: 540px) {
		top: 105%;
	}
	@media (min-height: 580px) {
		top: 103%;
	}
	@media (min-height: 600px) {
		top: 100%;
	}

	@media (min-width: 360px) {
		top: 129%;
		width: 1728px;
		height: 1728px;

		@media (min-height: 440px) {
			top: 124%;
		}
		@media (min-height: 470px) {
			top: 122%;
		}
		@media (min-height: 500px) {
			top: 120%;
		}
		@media (min-height: 540px) {
			top: 111.5%;
		}
		@media (min-height: 580px) {
			top: 109%;
		}
		@media (min-height: 600px) {
			top: 106%;
		}
		@media (min-height: 680px) {
			top: 100%;
		}
	}

	@media (min-width: 375px) {
		top: 132%;
		width: 1800px;
		height: 1800px;

		@media (min-height: 440px) {
			top: 127%;
		}
		@media (min-height: 470px) {
			top: 125%;
		}
		@media (min-height: 500px) {
			top: 122.5%;
		}
		@media (min-height: 540px) {
			top: 114%;
		}
		@media (min-height: 580px) {
			top: 111%;
		}
		@media (min-height: 600px) {
			top: 108%;
		}
		@media (min-height: 680px) {
			top: 102.5%;
		}
		@media (min-height: 720px) {
			top: 100%;
		}
	}

	@media (min-width: 390px) {
		top: 135%;
		width: 1870px;
		height: 1870px;

		@media (min-height: 440px) {
			top: 130%;
		}
		@media (min-height: 470px) {
			top: 128%;
		}
		@media (min-height: 500px) {
			top: 125%;
		}
		@media (min-height: 540px) {
			top: 117%;
		}
		@media (min-height: 580px) {
			top: 113%;
		}
		@media (min-height: 600px) {
			top: 110%;
		}
		@media (min-height: 680px) {
			top: 104.5%;
		}
		@media (min-height: 720px) {
			top: 102%;
		}
		@media (min-height: 750px) {
			top: 100%;
		}
	}

	@media (min-width: 425px) {
		top: 141%;
		width: 2000px;
		height: 2000px;

		@media (min-height: 440px) {
			top: 136%;
		}
		@media (min-height: 470px) {
			top: 133%;
		}
		@media (min-height: 500px) {
			top: 130%;
		}
		@media (min-height: 540px) {
			top: 122%;
		}
		@media (min-height: 580px) {
			top: 117%;
		}
		@media (min-height: 600px) {
			top: 114%;
		}
		@media (min-height: 680px) {
			top: 108%;
		}
		@media (min-height: 720px) {
			top: 105%;
		}
		@media (min-height: 750px) {
			top: 103%;
		}
		@media (min-height: 800px) {
			top: 100%;
		}
	}

	@media (min-width: 440px) {
		top: 146%;
		width: 2112px;
		height: 2112px;

		@media (min-height: 440px) {
			top: 140.5%;
		}
		@media (min-height: 470px) {
			top: 137%;
		}
		@media (min-height: 500px) {
			top: 134%;
		}
		@media (min-height: 540px) {
			top: 126%;
		}
		@media (min-height: 580px) {
			top: 120%;
		}
		@media (min-height: 600px) {
			top: 117.5%;
		}
		@media (min-height: 680px) {
			top: 111%;
		}
		@media (min-height: 720px) {
			top: 108%;
		}
		@media (min-height: 750px) {
			top: 105.5%;
		}
		@media (min-height: 800px) {
			top: 102.5%;
		}
		@media (min-height: 850px) {
			top: 100%;
		}

		// width: percentage(1800/375);
		// height: 0;
		// padding: 0 0 percentage(1800/375);
	}

	// width: 100%;
	// height: 0;
	// top: 0;
	// left: 0;
	// padding: 0 0 100%;
	// transform: none;

	// @media (min-height: 440px) {
	// 	top: percentage(760/635);
	// }
	// @media (min-height: 600px) and (min-width: 360px) {
	// 	top: percentage(755/635);
	// }
	// @media (min-height: 650px) and (min-width: 375px) {
	// 	top: percentage(745/635);
	// }
	// @media (min-height: 680px) and (min-width: 375px) {
	// 	top: percentage(670/635);
	// }
	// @media (min-height: 440px) and (min-width: 414px) {
	// 	top: percentage(730/635);
	// }
	// @media (min-height: 700px) and (min-width: 414px) {
	// 	top: percentage(670/635);
	// }
}

.spinner_wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
}

.device {
	position: absolute;
	top: 101%;
	left: 50%;
	width: 48px;
	height: 184px;
	margin: 0 -24px;
	background-image: url('../../../assets/images/vusego.png');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0;
	transition: all 400ms ease-in-out;

	@media (min-height: 440px) {
		width: 50px;
		height: 193px;
		margin: 0 -25px;
	}
	@media (min-height: 600px) and (min-width: 360px) {
		width: 58px;
		height: 223px;
		margin: 0 -28px;
	}
	@media (min-height: 650px) and (min-width: 375px) {
		width: 62px;
		height: 239px;
		margin: 0 -31px;
	}
	@media (min-height: 680px) and (min-width: 375px) {
		width: 68px;
		height: 262px;
		margin: 0 -34px;
	}
	// @media (min-height: 440px) and (min-width: 414px) {
	// }
	@media (min-height: 700px) and (min-width: 414px) {
		width: 72px;
		height: 277px;
		margin: 0 -36px;
	}
}
.device_uk{
	background-image: url('../../../assets/images/device-epod2.png');
}

.device_za {
	background-image: url('../../../assets/images/vusego.png');
}
.device_visible {
	margin-top: -115px;
	opacity: 1;

	@media (min-height: 440px) {
		margin-top: -120px;
	}
	@media (min-height: 600px) and (min-width: 360px) {
		margin-top: -138px;
	}
	@media (min-height: 650px) and (min-width: 375px) {
		margin-top: -150px;
	}
	@media (min-height: 680px) and (min-width: 375px) {
		margin-top: -170px;
	}
	// @media (min-height: 440px) and (min-width: 414px) {
	// }
	@media (min-height: 700px) and (min-width: 414px) {
		margin-top: -180px;
	}
}

.draggable {
	position: absolute;
	bottom: 0;
	left: -25%;
	width: 150%;
	height: 0;
	padding: 0 0 150%;
	margin: 0 0 -75%;
	// background: rgba(255, 255, 255, 0.3);
	z-index: 10;
}

// .draggable_middle {
// 	position: absolute;
// 	width: 4px;
// 	height: 4px;
// 	top: 50%;
// 	left: 50%;
// 	margin: -2px;
// 	background: #f00;
// }

// ======================================================================
// BUTTONS
// ======================================================================

.buttons {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 5px;
	margin: 40px auto;
	z-index: 10;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
}

.btn {
	display: inline-block;
	min-width: 80px;
	padding: 10px;
	margin: 2px;
	background: rgba(255, 255, 255, 0.8);
	border: none;
	border-radius: 3px;
	text-align: center;
	text-decoration: none;
	color: #000;
	cursor: pointer;

	&:hover {
		background: #fff;
	}
}
